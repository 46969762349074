//STG
const url = "https://node-digitalbank.mobiloitte.io/api/v1";
export const baseUrl = "https://node-digitalbank.mobiloitte.io";

//Live
// const url = "https://node.vdbcoin.com/api/v1";
// export const baseUrl = "https://node.vdbcoin.com";

const user = `${url}/user`;
const cmc = `${url}/cmc`;
const messarie = `${url}/messari`;
const contactUsForm = `${url}/contactUs`;
const faqs = `${url}/faq`;
const blog = `${url}/blog`;
const static1 = `${url}/static`;

const ApiConfig = {
  cmcData: `${cmc}/cmc`,
  messari: `${messarie}/messari`,
  myWallet: `${user}/listMyWallet`,
  contactUS: `${contactUsForm}/contactUs`,
  subscribeToNewsLetter: `${user}/subscribeToNewsLetter`,
  faqList: `${faqs}/faq`,
  blogList: `${blog}/listBlog`,
  StaticData: `${static1}/static`,
};

export default ApiConfig;
